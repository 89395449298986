@import '@theme/variables.scss';
.payment_select {
  position: absolute;
  right: -0.625rem;
  bottom: -0.625rem;
  width: 1.875rem;
  height: 1.875rem;
  background: $white;
  border-radius: 100%;
}
.cards-icon {
  height: 3.75rem;
  .paypal-logo {
    width: 3.75rem;
  }
  .payment-logo {
    width: 3.75rem;
  }
  .pay-direct-logo {
    width: 85px;
    height: 40px;
    margin-top: 10px;
  }
}
